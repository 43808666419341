import Worker from './view/Worker';
import Home from './view/Home';
import Error404 from './view/Error404';

export default class App {

  constructor() {
    this.worker = new Worker();
    this.home = new Home();
    this.error404 = new Error404();
  }

}
