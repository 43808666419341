export default class Worker {
    constructor() {
        this.getHeaderBlack();
    }

    getHeaderBlack() {
        if ($('body').hasClass('single-workers') || ($('body').hasClass('error404')) || ($('body').hasClass('privacy-policy')) || ($('body').hasClass('page-id-2027')) || ($('body').hasClass('page-id-2041')))
        {
            $('.header-navigation-menu').addClass('black-header');
        };
    }

}


