// import {
//   TweenLite
// } from "gsap/TweenLite";
// import {
//   CSSPlugin
// } from "gsap/CSSPlugin";
// import device from 'current-device';

window.$ = window.jQuery = jQuery;
import App from './App';

function ready(fn) {
  if (document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

ready(() => {
  getApp(); 
});

function getApp() {
  if (!window.app) {
      const app = new App();
    window.app = app;
  }

  return window.app = app;
}
