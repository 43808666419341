export default class Home {
    constructor() {
        this.blockLink();
        this.partnersLink();
    }

    //Function to wrap the conferences blocks in corresponding links
    blockLink() {
        let link = '';
        let title  = '';
        let blocks = document.querySelectorAll('.conference-block-wrapper');
        blocks.forEach(block => {
            link = $(block).find('a').attr('href');
            title = $(block).find('h3').text();
            $(block).wrap("<a class='link-wrap' href='"+link+"' title='Go to "+title+" page' target='_blank'></a>");
        });
    }

    partnersLink() {
        let links = document.querySelectorAll('.carousel-clients a');
        links.forEach(link => {
            $(link).attr('target', '_blank');
        });
    }
}
