export default class Error404 {
    constructor() {
        this.insertLink();
    }

    insertLink() {
        if ($('body').hasClass('error404')) {
            $('.error-404').append('<a class="backHome" href="http://wordpress.test/" title="Back to homepage">Go back to homepage</a>');
        }
    }
}